import type { GetStaticPaths, GetStaticProps, NextPage } from 'next'
import React from 'react'
import { NextSeo } from 'next-seo'
import { groq } from 'next-sanity'
import {
  getRouteByPath,
  getClient,
  getSiteConfiguration,
} from '@portfolio/services/sanity-service'
import { generateLocalePaths } from '@portfolio/utils'
import { PageBuilderSection, PageResponse } from '@portfolio/models'
import { useRouter } from 'next/router'
import { seoImageLoader } from '@portfolio/components'
import { DefaultPage } from '@portfolio/page'
import { DEFAULT_REVALIDATE_TIME } from '@portfolio/configs'
import { captureException } from '@sentry/nextjs'

const Page: NextPage<PageResponse<PageBuilderSection>> = (props) => {
  const { seo, disallowRobots } = props

  const { asPath } = useRouter()
  const fullUrl = `${process.env.BASE_URL}${asPath}`
  const canonicalUrl = `${process.env.BASE_URL}${
    asPath === '/' ? '' : asPath.split('?')[0]
  }`
  const seoImage = seoImageLoader(seo?.ogImage, seo?.ogAltImage)

  const seoElement = seo ? (
    <NextSeo
      titleTemplate={seo?.titleTemplate}
      nofollow={disallowRobots}
      noindex={disallowRobots}
      title={seo.metaTitle}
      description={seo.metaDescription}
      canonical={canonicalUrl}
      openGraph={{
        url: fullUrl,
        type: 'website',
        title: seo.ogTitle,
        description: seo.ogDescription,
        images: [...(seoImage ? [seoImage] : [])],
      }}
      twitter={{
        cardType: 'summary_large_image',
      }}
    />
  ) : (
    <NextSeo
      noindex={disallowRobots}
      nofollow={disallowRobots}
      canonical={fullUrl}
    />
  )

  return (
    <>
      {seoElement}
      <DefaultPage {...props} />
    </>
  )
}

export const getStaticPaths: GetStaticPaths = async ({ defaultLocale }) => {
  const paths: string[] = await getClient(false).fetch(
    groq`*[!(_id in path('drafts.**')) && defined(path.current) && _type != 'hydrogen'][].path.current`,
  )

  return {
    paths: generateLocalePaths(paths, defaultLocale),
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  PageResponse<PageBuilderSection[]>
> = async ({ params, locale, preview = false }) => {
  const { paths } = params

  if (typeof paths === 'string') {
    throw new Error('paths must be passed as an array. Received string')
  }

  const path = `/${paths ? paths.join('/') : ''}`

  const pageData = await getRouteByPath(path, locale, preview).catch((e) => {
    captureException(e)
    throw e
  })

  if (!pageData) {
    return {
      notFound: true,
    }
  }

  const siteConfiguration = await getSiteConfiguration(locale, preview)

  return {
    props: {
      ...pageData,
      siteConfiguration,
    },
    revalidate: DEFAULT_REVALIDATE_TIME,
  }
}

export default Page
